'use client'
import dynamic from 'next/dynamic'
import React, { ReactNode } from 'react'
import { UserActionLogContext, useUserActionLog } from 'src/context/useUserActionLog'
const ItemNLPForm = dynamic(() => import('@/features/forms/ItemNLPForm'))

const Contexts = ({ children }: { children: ReactNode }) => {
    const userActionLogProps = useUserActionLog()

    return (
        <>
            <UserActionLogContext.Provider value={userActionLogProps}>
                {children}
                <ItemNLPForm />
            </UserActionLogContext.Provider>
        </>
    )
}

export default Contexts
