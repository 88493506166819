type DraggableCreator<T> = {
    prefix: string
    create: (params: T) => string
    isValidId: (id: string) => boolean
    disassemble: (activeId: string) => T
}

type DraggableCreators = {
    listItem: DraggableCreator<{ itemId: string }>
    category: DraggableCreator<{
        categoryId: string
        parentId: string
    }>
    calendarItem: DraggableCreator<{ itemId: string }>
    searchTaskOption: DraggableCreator<{ taskId: string }>
    /** itemBullet should be changed back to calendarItem */
    itemBullet: DraggableCreator<{ itemId: string }>
}

type StaticIds = {
    // panda: string
}

export const DRAGGABLE_ID: StaticIds & DraggableCreators = {
    // // Static IDs
    // panda: 'draggable-panda',

    // Dynamic ID functions with prefixes
    listItem: {
        prefix: 'task#',
        create: ({ itemId }: { itemId: string }) => `${DRAGGABLE_ID.listItem.prefix}${itemId}`,
        disassemble: (id: string) => {
            const taskId = id.replace(DRAGGABLE_ID.listItem.prefix, '')
            return { itemId: taskId }
        },
        isValidId: (id: string) => id.startsWith(DRAGGABLE_ID.listItem.prefix),
    },

    category: {
        prefix: 'category#',
        create: ({ categoryId, parentId }: { categoryId: string; parentId: string }) =>
            `${DRAGGABLE_ID.category.prefix}${categoryId}#${parentId}`,
        disassemble: (id: string) => {
            const [categoryId, parentId] = id.replace(DRAGGABLE_ID.category.prefix, '').split('#')
            return { categoryId, parentId }
        },
        isValidId: (id: string) => id.startsWith(DRAGGABLE_ID.category.prefix),
    },

    calendarItem: {
        prefix: 'calendar#',
        create: ({ itemId }: { itemId: string }) => `${DRAGGABLE_ID.calendarItem.prefix}${itemId}`,
        disassemble: (id: string) => {
            const itemId = id.replace(DRAGGABLE_ID.calendarItem.prefix, '')
            return { itemId }
        },
        isValidId: (id: string) => id.startsWith(DRAGGABLE_ID.calendarItem.prefix),
    },

    itemBullet: {
        prefix: 'item-bullet#',
        create: ({ itemId }: { itemId: string }) => `${DRAGGABLE_ID.itemBullet.prefix}${itemId}`,
        disassemble: (id: string) => {
            const itemId = id.replace(DRAGGABLE_ID.itemBullet.prefix, '')
            return { itemId }
        },
        isValidId: (id: string) => id.startsWith(DRAGGABLE_ID.itemBullet.prefix),
    },

    searchTaskOption: {
        prefix: 'search-task#',
        create: ({ taskId }: { taskId: string }) =>
            `${DRAGGABLE_ID.searchTaskOption.prefix}${taskId}`,
        disassemble: (id: string) => {
            const taskId = id.replace(DRAGGABLE_ID.searchTaskOption.prefix, '')
            return { taskId }
        },
        isValidId: (id: string) => id.startsWith(DRAGGABLE_ID.searchTaskOption.prefix),
    },
} as const

export const getItemIdFromActiveId = (activeId: string) => {
    // TODO: should I remove calendar item?
    if (DRAGGABLE_ID.calendarItem.isValidId(activeId)) {
        const { itemId } = DRAGGABLE_ID.calendarItem.disassemble(activeId)
        return itemId
    }

    // TODO: may be able to join task and itemBullet, just make sure to use style to differentiate
    if (DRAGGABLE_ID.listItem.isValidId(activeId)) {
        const { itemId: taskId } = DRAGGABLE_ID.listItem.disassemble(activeId)
        return taskId
    }
    if (DRAGGABLE_ID.itemBullet.isValidId(activeId)) {
        const { itemId } = DRAGGABLE_ID.itemBullet.disassemble(activeId)
        return itemId
    }
    return null
}
