import { useAppDispatch, useAppSelector } from '@/redux/hooks'
import { editDisplayOptions } from '@/redux/features/displayPreferencesSlice'
import { DisplayOptions } from '@/types/display'

// TODO: make this a redux thing
export function useTaskDisplayOptions() {
    const displayOptions = useAppSelector((state) => state.displayPreferences.taskDisplayOptions)
    const dispatch = useAppDispatch()
    // const [displayOptions, setDisplayOptions] = useState<DisplayOptions>(DEFAULT_DISPLAY_OPTIONS)

    // useEffect(() => {
    //     updateOptions()
    // }, [])

    // async function updateOptions() {
    //     const newDisplayOptions: Record<string, any> = {}
    //     const promises = Object.entries(DEFAULT_DISPLAY_OPTIONS).map(async ([key, value]) => {
    //         const isValid = (typeof value === 'boolean') ? isBoolean : isString
    //         const storedValue = await getForageItem('task-display--' + key, isValid, value)
    //         newDisplayOptions[key] = storedValue
    //     })
    //     await Promise.all(promises)

    //     setDisplayOptions({ ...newDisplayOptions } as DisplayOptions)
    // }

    // useEffect(() => {
    //     window.addEventListener('storage', updateOptions);
    //     return () => {
    //         window.removeEventListener('storage', updateOptions);
    //     };
    // }, [])

    async function editOptions(field: keyof DisplayOptions, value: any) {
        dispatch(editDisplayOptions({ field, value }))
        // setDisplayOptions((p) => ({ ...p, [field]: value }))
        // await localforage.setItem('task-display--' + field, value)
        // window.dispatchEvent(new Event('storage'))
        // setDisplayOptions(getOptions())
        // why doesn't setDisplayOptions cause changes?
    }

    return {
        displayOptions,
        editOptions,
    }
}
