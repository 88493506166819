import { useUpdatePlannerItemMutation } from '@/redux/features/api'
import { Task } from '@/types'
import useTasks from './main/category/useTasks'
import { useCallback } from 'react'
import { defaultSortTasks } from '@/utils/item'
import { useTaskDisplayOptions } from './useTaskDisplayOptions'
import { UpdateItemParams } from 'dynamodb-helpers'

const useTaskListReorder = () => {
    const [update] = useUpdatePlannerItemMutation()
    const { tasks: items } = useTasks()
    const { displayOptions } = useTaskDisplayOptions()

    const reorderTaskList = useCallback(
        (cardId: string, before: string, additionalUpdates?: UpdateItemParams<Task>) => {
            if (!items) return
            const tasks = defaultSortTasks(items, displayOptions)
            const moveToBack = before === '-1'

            if (moveToBack) {
                additionalUpdates && update({ id: cardId, updates: additionalUpdates })
            } else {
                const index = tasks.findIndex((c) => c.id === before)
                if (index === -1) return
                const cardAfterNewPosition = tasks[index]
                // console.log('cardAfterNewPosition', cardAfterNewPosition.name, cardAfterNewPosition.order)
                if (index === 0) {
                    update({
                        id: cardId,
                        updates: {
                            ...additionalUpdates,
                            set: {
                                ...additionalUpdates?.set,
                                order:
                                    cardAfterNewPosition.order !== undefined
                                        ? cardAfterNewPosition.order - 100
                                        : 100,
                            },
                        },
                    })
                    return
                }
                const cardBeforeNewPosition = tasks[index - 1]
                if (
                    cardAfterNewPosition.order !== undefined &&
                    cardBeforeNewPosition.order !== undefined
                ) {
                    const newOrder = Math.round(
                        (cardAfterNewPosition.order + cardBeforeNewPosition.order) / 2
                    )
                    update({
                        id: cardId,
                        updates: {
                            ...additionalUpdates,
                            set: { ...additionalUpdates?.set, order: newOrder },
                        },
                    })
                    return
                } else if (cardBeforeNewPosition.order !== undefined) {
                    update({
                        id: cardId,
                        updates: {
                            ...additionalUpdates,
                            set: {
                                ...additionalUpdates?.set,
                                order: cardBeforeNewPosition.order + 100,
                            },
                        },
                    })
                    return
                }
                // both undefined, so have to order all tasks before
                const res = tasks.slice(0, index).map((c, i) => {
                    return update({ id: c.id, updates: { set: { order: (i + 1) * 100 } } })
                })
                update({
                    id: cardId,
                    updates: {
                        ...additionalUpdates,
                        set: { ...additionalUpdates?.set, order: (index + 1) * 100 },
                    },
                })
            }
        },
        [update, items, displayOptions]
    )

    return {
        reorderTaskList,
    }
}

export default useTaskListReorder
