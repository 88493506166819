type DroppableCreator<T> = {
    prefix: string
    create: (params: T) => string
    disassemble: (id: string) => T
    isValidId: (id: string) => boolean
}

type DroppableCreators = {
    kanban: DroppableCreator<{ status: string; beforeId: string }>
    taskListOrderIndicator: DroppableCreator<{ beforeId: string }>
    category: DroppableCreator<{ categoryId: string }>
    date: DroppableCreator<{ timestamp: number }>
    workingOnDay: DroppableCreator<{ timestamp: number }>
    allDayOrDueDateBox: DroppableCreator<{ timestamp: number }>
    workBlock: DroppableCreator<{ workBlockId: string; date: number }>
    priorityTableInstance: DroppableCreator<{ instanceId: string }>
    completedDay: DroppableCreator<{ timestamp: number }>
}

type StaticIds = {
    weeklyList: string
    /** Dragging here removes task from weekly list */
    notInWeeklyList: string
    burnBarrel: string
    priorityTable: string
    todaysList: string
}

export const DROPPABLE_ID: StaticIds & DroppableCreators = {
    // Static IDs
    weeklyList: 'weekly-list',
    notInWeeklyList: 'not-in-weekly-list',
    burnBarrel: 'burn-barrel',
    priorityTable: 'priority-table',
    todaysList: 'todays-list',

    // Dynamic ID functions with prefixes
    kanban: {
        prefix: 'kanban#',
        create: ({ status, beforeId }: { status: string; beforeId: string }) =>
            `${DROPPABLE_ID.kanban.prefix}${status}#${beforeId || '-1'}`,
        disassemble: (id: string) => {
            const [status, beforeId] = id.replace(DROPPABLE_ID.kanban.prefix, '').split('#')
            return { status, beforeId }
        },
        isValidId: (id: string) =>
            id.startsWith(DROPPABLE_ID.kanban.prefix) && id.split('#').length === 3,
    },

    taskListOrderIndicator: {
        prefix: 'task-list-drop-indicator#',
        create: ({ beforeId }: { beforeId: string }) =>
            `${DROPPABLE_ID.taskListOrderIndicator.prefix}${beforeId || '-1'}`,
        disassemble: (id: string) => {
            const beforeId = id.replace(DROPPABLE_ID.taskListOrderIndicator.prefix, '')
            return { beforeId }
        },
        isValidId: (id: string) => id.startsWith(DROPPABLE_ID.taskListOrderIndicator.prefix),
    },

    category: {
        prefix: 'CATEGORY#',
        create: ({ categoryId }: { categoryId: string }) =>
            `${DROPPABLE_ID.category.prefix}${categoryId}`,
        disassemble: (id: string) => {
            const categoryId = id.replace(DROPPABLE_ID.category.prefix, '')
            return { categoryId }
        },
        isValidId: (id: string) => id.startsWith(DROPPABLE_ID.category.prefix),
    },
    workingOnDay: {
        prefix: 'workingOnDay#',
        create: ({ timestamp }: { timestamp: number }) =>
            `${DROPPABLE_ID.workingOnDay.prefix}${timestamp}`,
        disassemble: (id: string) => {
            const timestamp = parseInt(id.replace(DROPPABLE_ID.workingOnDay.prefix, ''))
            return { timestamp }
        },
        isValidId: (id: string) =>
            id.startsWith(DROPPABLE_ID.workingOnDay.prefix) &&
            !isNaN(parseInt(id.replace(DROPPABLE_ID.workingOnDay.prefix, ''))),
    },

    /** FOR CALENDAR ONLY */
    date: {
        prefix: '',
        create: ({ timestamp }: { timestamp: number }) => timestamp.toString(),
        disassemble: (id: string) => {
            return { timestamp: parseInt(id) }
        },
        isValidId: (id: string) => !isNaN(parseInt(id)),
    },
    /** TODO: UNUSED. Replace All-day box droppable with this. Changes events to all day, changes task due date (day only, no time) */
    allDayOrDueDateBox: {
        prefix: 'eventStartEnd#',
        create: ({ timestamp }: { timestamp: number }) =>
            `${DROPPABLE_ID.allDayOrDueDateBox.prefix}${timestamp}`,
        disassemble: (id: string) => {
            const timestamp = parseInt(id.replace(DROPPABLE_ID.allDayOrDueDateBox.prefix, ''))
            return { timestamp }
        },
        isValidId: (id: string) =>
            id.startsWith(DROPPABLE_ID.allDayOrDueDateBox.prefix) &&
            !isNaN(parseInt(id.replace(DROPPABLE_ID.allDayOrDueDateBox.prefix, ''))),
    },
    /** CALENDAR END */

    workBlock: {
        prefix: 'workBlock#',
        create: ({ workBlockId, date }: { workBlockId: string; date: number }) =>
            `${DROPPABLE_ID.workBlock.prefix}${workBlockId}#${date}`,
        disassemble: (id: string) => {
            const [workBlockId, date] = id.replace(DROPPABLE_ID.workBlock.prefix, '').split('#')
            return { workBlockId, date: parseInt(date) }
        },
        isValidId: (id: string) =>
            id.startsWith(DROPPABLE_ID.workBlock.prefix) && id.split('#').length === 3,
    },

    priorityTableInstance: {
        prefix: 'priority-table#',
        create: ({ instanceId }: { instanceId: string }) =>
            `${DROPPABLE_ID.priorityTableInstance.prefix}${instanceId}`,
        disassemble: (id: string) => {
            const instanceId = id.replace(DROPPABLE_ID.priorityTableInstance.prefix, '')
            return { instanceId }
        },
        isValidId: (id: string) => id.startsWith(DROPPABLE_ID.priorityTableInstance.prefix),
    },

    // TODO: drag handling not implemented yet
    completedDay: {
        prefix: 'completedDay#',
        create: ({ timestamp }: { timestamp: number }) =>
            `${DROPPABLE_ID.completedDay.prefix}${timestamp}`,
        disassemble: (id: string) => {
            const timestamp = parseInt(id.replace(DROPPABLE_ID.completedDay.prefix, ''))
            return { timestamp }
        },
        isValidId: (id: string) =>
            id.startsWith(DROPPABLE_ID.completedDay.prefix) &&
            !isNaN(parseInt(id.replace(DROPPABLE_ID.completedDay.prefix, ''))),
    },
} as const

export type DroppableId = keyof typeof DROPPABLE_ID
export type DroppableTimestampKey = 'date' | 'workingOnDay' | 'allDayOrDueDateBox' | 'completedDay'
