import { forOwn, isEmpty, isPlainObject } from "lodash"
import { DatedItem, NewItem, SchoolEnum } from "src/types"
import { UpdateItemParams } from "dynamodb-helpers"
import { jsonParse, jsonStringify } from "@planda/utils"

export function paramsToQueryString(params = {}) {
    return Object.entries(params)
        .filter(param => param[1] !== undefined)
        .map(param => `${param[0]}=${param[1]}`)
        .join("&")
}
const fixUrl = (url: string) => url.replace(/#/g, '%23')

export async function fetchGet<ResType>(url: string, params = {}, options?: {
    headers?: Record<string, string>,
    fallback?: ResType
}): Promise<ResType> {
    if (url.includes("undefined")) console.log(url)
    const { fallback, headers } = options || {}
    const queryString = paramsToQueryString(params)
    const urlWithParams = `${url}${queryString ? (url.includes('?') ? '&' : '?') + queryString : ''}`
    // console.log('fetchGet', urlWithParams)
    return fetch(fixUrl(urlWithParams), { method: 'GET', headers }).then(async (res) => {
        if (res.ok) {
            return jsonParse(await res.text())
        } else {
            console.error(res.status, res.statusText);
            return fallback;
        }
    })
}

const walk = (node: any) => {
    if (node.value instanceof Set) {
        node.value = [...node.value]
        return
    }
    if (!node.value || !isPlainObject(node.value)) return
    forOwn(node.value, walk)
}

export async function fetcher<T>(url: string, method: string, body = {}, queryParams = {}, fallback?: T): Promise<T> {
    const res = await fetch(fixUrl(`${url}?${paramsToQueryString(queryParams)}`), {
        method,
        headers: { "Content-Type": "application/json" },
        ...(!isEmpty(body) && { body: jsonStringify(body) }),
    }).then(async (res) => {
        if (res.ok) {
            const text = await res.text()
            if (text.toLowerCase().startsWith('success')) return fallback
            return jsonParse(text);
        } else {
            // console.error(res.status, res.statusText);
            return fallback;
        }
    }).catch((err) => {
        // console.error(err);
        return fallback;
    })
    return res
}

export async function fetchPatchItem(id: string, updates: UpdateItemParams) {
    await fetcher('/api/main/item', 'PATCH', { ...updates, id })
}

export async function fetchDeleteItem(id: string) {
    await fetcher('/api/main/item', 'DELETE', { id })
}

export async function fetchPutItem(item: NewItem | Partial<DatedItem>) {
    await fetcher('/api/main/item', 'POST', item)
}
export async function fetchPatchCategoryUnit(id: string, updates: UpdateItemParams) {
    await fetcher('/api/main/categoryUnit', 'PATCH', { id, updates })
}

export const fetchGoogleCalendarEvents = async () => {
    // await listGoogleEvents(start, end)
    await fetch(`/api/external/calendar/google?fullSync=true`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
    }).then(res => {
        if (res.ok) {
            return res.json();
        } else {
            console.error(res.status, res.statusText);
            throw Error(`${res.status} - ${res.statusText}`);
        }
    })
        .catch(error => {
            console.log('There is some error', error);
        });
}

export async function googleCalendarIsEnabled() {
    const res = await fetchGet<{ isEnabled: boolean }>('/api/external/calendar/google')
    return !!res?.isEnabled
}

export function fetchDeleteUser() {
    const prompt = window.prompt("Are you sure you want to delete your Planda account? This action is irreversible. Type 'confirm' to continue.")
    if (!prompt || prompt.trim().toLowerCase() !== 'confirm') return false
    const confirm2 = window.confirm("I understand that all of my account data will be deleted, and cannot be recovered.")
    if (!confirm2) return false
    fetcher('/api/user', 'DELETE')
    return true
}

export async function fetchClientFileUrl(id: string) {
    const res = await fetchGet<{ url?: string }>(`/api/client-file/${id}`)
    return res?.url
}

export async function fetchApplySchool(school?: SchoolEnum) {
    return await fetcher('/api/user/school', 'PUT', undefined, {
        school
    })
}
