import React, { useCallback } from 'react'
import {
    DndContext,
    useSensor,
    MouseSensor,
    TouchSensor,
    KeyboardSensor,
    useSensors,
    DroppableContainer,
    ClientRect,
    DragEndEvent,
    Over,
} from '@dnd-kit/core'
import { restrictToWindowEdges } from '@dnd-kit/modifiers'
import { rectIntersection, closestCenter } from '@dnd-kit/core'
import { isDBEventId, isDBTaskId, isDBTemplateRecurId, isEvent, isTask, Task } from '@/types'
import { DRAGGABLE_ID, getItemIdFromActiveId } from '@/types/draggable'
import { DROPPABLE_ID } from '@/types/droppable'
import { usePutAchievementMutation, useUpdatePlannerItemMutation } from '@/redux/features/api'
import { useUser } from '@/hooks/useUser'
import { isSameDay, startOfWeek } from 'date-fns'
import { changeDay, DayOfWeekNum, toDayStringFormat } from '@planda/utils'
import { usePutPlannerItem } from '@/hooks/main/category/usePutPlannerItem'
import useFindItem from '@/hooks/main/category/useFindItem'
import { taskToAllDayTaskEvent } from '@/utils/item'
import useTaskListReorder from '@/hooks/useTaskListReorder'
import { isString } from 'lodash'
import { UpdateItemParams } from 'dynamodb-helpers'

const activationConstraint = {
    // delay: 30,
    // tolerance: 0,
    distance: 2,
} as const

/** All droppables and draggables handling, except for those in calendar (those require eventSet.find) */
const DndWrapper = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
    //#region sensors
    const mouseSensor = useSensor(MouseSensor, { activationConstraint })
    const touchSensor = useSensor(TouchSensor, { activationConstraint })
    // const keyboardSensor = useSensor(KeyboardSensor, {});
    const sensors = useSensors(mouseSensor, touchSensor)
    const { user } = useUser()
    const { putItem } = usePutPlannerItem()
    const [updatePlannerItem] = useUpdatePlannerItemMutation()
    const [putAchievement] = usePutAchievementMutation()
    const { reorderTaskList } = useTaskListReorder()

    const { find } = useFindItem()
    //#endregion sensors

    const weekStartsOn = ((user?.weekStartsOn ?? 0) % 7) as DayOfWeekNum

    const handleDragEnd = useCallback(
        async (event: DragEndEvent) => {
            const { active, over, delta } = event
            let updates: { id: string; updates?: UpdateItemParams<Task> } | undefined

            if (typeof active.id !== 'string') return

            if (!over?.id || typeof over.id !== 'string') return
            const source = active.data.current?.dndKitUseDraggableSource as string | undefined

            if (over.id === DROPPABLE_ID.weeklyList || over.id === DROPPABLE_ID.notInWeeklyList) {
                if (!DRAGGABLE_ID.listItem.isValidId(active.id)) return
                const { itemId: taskId } = DRAGGABLE_ID.listItem.disassemble(active.id)

                // add task to weekly list
                updates = {
                    id: taskId,
                    updates: {
                        [over.id === DROPPABLE_ID.weeklyList ? 'add' : 'delete']: {
                            weeksAssigned: [
                                toDayStringFormat(startOfWeek(Date.now(), { weekStartsOn })),
                            ],
                        },
                    },
                }
            } else if (over.id === DROPPABLE_ID.todaysList) {
                if (!DRAGGABLE_ID.listItem.isValidId(active.id)) return
                const { itemId: taskId } = DRAGGABLE_ID.listItem.disassemble(active.id)
                // add task to weekly list
                const item = await find(taskId)
                if (!item || !isTask(item)) return // TODO TOAST
                putItem(taskToAllDayTaskEvent(item, Date.now()))
                updates = {
                    id: taskId,
                }
            } else if (DROPPABLE_ID.workingOnDay.isValidId(over.id)) {
                // TODO: what about multi-day or recurring events?
                // maybe for now show an alert that recurring events can't be moved
                // multi-day events need to be moved by offset.
                const { timestamp } = DROPPABLE_ID.workingOnDay.disassemble(over.id)
                const itemId = getItemIdFromActiveId(active.id)
                if (!itemId) return
                const rootItem = await find(itemId)
                if (!rootItem) return

                // TODO: if event, keep duration and change day to match new date
                // if task, either create a task event or change day it is due (probably create task event)
                // best to split into multiple droppables. one for day change, one for datetime change, one for creating all task events

                if (isEvent(rootItem)) {
                    const duration = rootItem.dateEnd - rootItem.dateStart
                    const newDateStart = changeDay(rootItem.dateStart, timestamp).getTime()
                    if (isSameDay(newDateStart, rootItem.dateStart)) return
                    updatePlannerItem({
                        id: rootItem.id,
                        updates: {
                            set: {
                                dateStart: newDateStart,
                                dateEnd: newDateStart + duration,
                            },
                        },
                    }).then((res) => {
                        if (res.error) return
                        if (source === 'todo-task-search') {
                            putAchievement({
                                achievementId: 'week-list-drag-item-bullet',
                                proof: rootItem.id, // no childType since event is not a template
                            })
                        }
                    })
                    return
                } else if (isTask(rootItem)) {
                    putItem(taskToAllDayTaskEvent(rootItem, timestamp), {
                        achievement: 'week-list-add-to-schedule-drag',
                    })
                    updates = { id: rootItem.id }
                }
            }

            const itemId = updates?.id ?? active.id
            const beforeId = getBeforeId(over)
            if (beforeId && isDBTaskId(itemId) && beforeId !== itemId) {
                reorderTaskList(itemId, beforeId, updates?.updates)
                return
            } else if (updates?.updates) {
                updatePlannerItem({
                    id: updates.id,
                    updates: updates.updates,
                })
            }
        },
        [updatePlannerItem, weekStartsOn, find, putItem, putAchievement, reorderTaskList]
    )

    return (
        <DndContext
            sensors={sensors}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToWindowEdges]} //2rem, calc((100% - 3.25rem) / 7)
            collisionDetection={customCollisionDetectionAlgorithm}
        >
            {children}
        </DndContext>
    )
}

export default DndWrapper

/** prioritizes workBlock containers **/
function customCollisionDetectionAlgorithm({ droppableContainers, ...args }: any) {
    const collisionRect: ClientRect = args.collisionRect
    const workOnContainers = (droppableContainers as DroppableContainer[]).filter(
        ({ id }) => typeof id === 'string' && (isDBEventId(id) || isDBTemplateRecurId(id, 'event'))
    )
    const workOnCollisions = rectIntersection({
        ...args,
        droppableContainers: workOnContainers,
    })
    if (workOnCollisions.length > 0) return workOnCollisions

    const possibleIntersections = rectIntersection({
        ...args,
        droppableContainers,
        collisionRect: expandCollisionRect(collisionRect),
    })
    const possibleContainerIds = new Set(possibleIntersections.map(({ id }) => id))

    const withinRectContainers = (droppableContainers as DroppableContainer[]).filter(({ id }) =>
        possibleContainerIds.has(id)
    )

    const centerCollisions = closestCenter({
        ...args,
        droppableContainers: withinRectContainers,
    })

    return centerCollisions
    // .filter(({ id }) => possibleContainerIds.has(id));

    // // First, let's see if the `trash` droppable rect is intersecting
    // const rectIntersectionCollisions = rectIntersection({
    //     ...args,
    //     droppableContainers: droppableContainers.filter(({ id }) => id === 'trash')
    // });

    // // Collision detection algorithms return an array of collisions
    // if (rectIntersectionCollisions.length > 0) {
    //     // The trash is intersecting, return early
    //     return rectIntersectionCollisions;
    // }
    // // export interface Collision {
    // //     id: UniqueIdentifier;
    // //     data?: Data;
    // // }
    // // const col: Collision = []

    // // Compute other collisions
    // return closestCorners({
    //     ...args,
    //     droppableContainers: droppableContainers.filter(({ id }) => id !== 'trash')
    // });
}

const expandCollisionRect = (collisionRect: ClientRect) => {
    const addOn = Math.min(collisionRect.width, collisionRect.height) / 2
    // const additionalTargetHeight = collisionRect.height / 2;
    // const additionalTargetWidth = collisionRect.width / 2;
    const doubledCollisionRect = {
        ...collisionRect,
        width: collisionRect.width + addOn * 2,
        height: collisionRect.height + addOn * 2,
        top: collisionRect.top - addOn,
        left: collisionRect.left - addOn,
        right: collisionRect.right + addOn,
        bottom: collisionRect.bottom + addOn,
    }
    return doubledCollisionRect
}

const getBeforeId = (over: Over) => {
    const { beforeId } = over.data.current || {}
    if (isString(beforeId)) return beforeId
    if (isString(over.id) && DROPPABLE_ID.taskListOrderIndicator.isValidId(over.id)) {
        const { beforeId: beforeId2 } = DROPPABLE_ID.taskListOrderIndicator.disassemble(over.id)
        return beforeId2
    }
    return null
}

// /**
//  * Returns the intersecting rectangle area between two rectangles
//  * A more forgiving implementation than dnd-kit's getIntersectionRatio
//  */
// function getIntersectionRatio(
//     entry: ClientRect, // container
//     target: ClientRect // active
// ): number {
//     const additionalTargetHeight = target.height / 2;
//     const additionalTargetWidth = target.width / 2;

//     const top = Math.max(target.top - additionalTargetHeight, entry.top); // smallest top
//     const left = Math.max(target.left - additionalTargetWidth, entry.left); // rightmost left
//     const right = Math.min(target.left + target.width + additionalTargetWidth, entry.left + entry.width);
//     const bottom = Math.min(target.top + target.height + additionalTargetHeight, entry.top + entry.height);
//     const width = right - left;
//     const height = bottom - top;

//     if (left < right && top < bottom) {
//         const targetArea = target.width * target.height;
//         const entryArea = entry.width * entry.height;
//         const intersectionArea = width * height;
//         const intersectionRatio =
//             intersectionArea / (targetArea + entryArea - intersectionArea);

//         return Number(intersectionRatio.toFixed(4));
//     }

//     // Rectangles do not overlap, or overlap has an area of zero (edge/corner overlap)
//     return 0;
// }

// /**
//  * Returns the closest rectangles from an array of rectangles to the center of a given
//  * rectangle.
//  */
// export const closestCenter: CollisionDetection = ({
//     collisionRect,
//     droppableRects,
//     droppableContainers,
// }) => {
//     const centerRect = centerOfRectangle(
//         collisionRect,
//         collisionRect.left,
//         collisionRect.top
//     );
//     const collisions: CollisionDescriptor[] = [];

//     for (const droppableContainer of droppableContainers) {
//         const { id } = droppableContainer;
//         const rect = droppableRects.get(id);

//         if (rect) {
//             const distBetween = distanceBetween(centerOfRectangle(rect), centerRect);

//             collisions.push({ id, data: { droppableContainer, value: distBetween } });
//         }
//     }

//     return collisions.sort(sortCollisionsAsc);
// };
