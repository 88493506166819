import useGetCategoryRes from '@/hooks/useGetCategoryRes'
import { Item } from '@/types'
import { getTypeFromId } from '@/utils/item'
import { fetchGet } from '@planda/utils'
import { useCallback } from 'react'

const useFindItem = () => {
    const queryRes = useGetCategoryRes()

    const findSync = useCallback(
        (id: string) => {
            const type = getTypeFromId(id)
            return queryRes.data?.items[type].find((x) => x.id === id)
        },
        [queryRes.data]
    )

    const find = useCallback(
        async (id: string) => {
            const item = findSync(id)
            if (item) return item
            // TODO: do a fetch for the item
            const res = await fetchGet<Item | undefined>(`/api/main/item`, { id })
            if (res) return res
            return null
        },
        [findSync]
    )

    return {
        ...queryRes,
        findSync,
        find,
    }
}

export default useFindItem
