import { useGetUserQuery, usePatchUserMutation } from '@/redux/features/api'
import { User } from '@/types'
import { DayOfWeekNum } from '@planda/design-system'

export function useUser() {
    const { data, error, isLoading } = useGetUserQuery()

    const weekStartsOn = (data?.weekStartsOn ?? 0 % 7) as DayOfWeekNum

    return {
        user: data,
        isLoading,
        isError: !!error,
        weekStartsOn,
    }
}
