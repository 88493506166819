'use client'
import { SessionProvider } from 'next-auth/react'
import { SWRConfig } from 'swr'
import { ToastProvider } from '@/components/common/toast'
import DndWrapper from '@/components/dnd/DndWrapper'
import Contexts from '@/layouts/Contexts'
import StoreProvider from '@/app/StoreProvider'
import { Provider as TooltipProvider } from '@radix-ui/react-tooltip'

type Props = {
    children?: React.ReactNode
}

export const ClientProviders = ({ children }: Props) => {
    return (
        <SWRConfig
            value={{
                onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
                    // Never retry on 404.
                    if (error.status === 404) return

                    // Only retry up to 10 times.
                    if (retryCount >= 3) return

                    // Retry after 5 seconds.
                    setTimeout(() => revalidate({ retryCount }), 5000)
                },
            }}
        >
            <SessionProvider refetchWhenOffline={false}>
                <StoreProvider>
                    <TooltipProvider delayDuration={0}>
                        <ToastProvider duration={3000} swipeDirection="right">
                            <DndWrapper>
                                {/* <ErrorBoundary> */}
                                <Contexts>{children}</Contexts>
                                {/* </ErrorBoundary> */}
                            </DndWrapper>
                        </ToastProvider>
                    </TooltipProvider>
                </StoreProvider>
            </SessionProvider>
        </SWRConfig>
    )
}
