import useActive from './useActive'
import { Event, Task } from 'src/types'

export default function useTasks(path?: string) {
    const props = useActive('task', path)
    const tasks = props.items as Task[] | undefined

    return {
        ...props,
        tasks
    }
}
